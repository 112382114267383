import React from "react";
import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div className="definition">
          <h1>Lantian</h1>
          <p>
            <i className="accent">adjective</i> <b>INFORMAL</b>
          </p>
          <p>
            creative or visionary and unconstrained by practicalities. <br />
            <span className="accent">"blue-sky thinking"</span>
          </p>
        </div>
        <div className="contact">
          <a className="accent" href="mailto:hello@lantian.io" target="_blank">
            hello@lantian.io
          </a>
        </div>
      </header>
    </div>
  );
}

export default App;
